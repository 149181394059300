export function audioPlayer() {
  const musicPlayer = document.querySelector(".music-player");
  if (musicPlayer) {
    let currentTrackIndex = 0;
    let isPlaying = false;
    let audio = new Audio();
    let rotationAngle = 0;
    let rotationSpeed = 1; // 角度の増加量を調整
    let animationFrameId;
    let workers = []; // Web Workerのリスト

    const imageArea = musicPlayer.querySelector(".image-area");
    const playPauseButton = musicPlayer.querySelector(".play-pause-button");
    const stopButton = musicPlayer.querySelector(".stop-button");
    const prevButton = musicPlayer.querySelector(".prev-button");
    const nextButton = musicPlayer.querySelector(".next-button");
    const playlistElement = musicPlayer.querySelector(".playlist");
    const progressContainer = musicPlayer.querySelector(".progress");
    const progressBar = progressContainer.querySelector(".time");
    const loadBar = progressContainer.querySelector(".load");
    const currentTimeElement = musicPlayer.querySelector(".current-time");
    const totalDurationElement = musicPlayer.querySelector(".total-duration");
    const trackTitleElement = musicPlayer.querySelector(".player-track-title");
    const trackArtistElement = musicPlayer.querySelector(".player-track-artist");

    const buffers = new Array(playlist.tracks.length).fill(null); // 各トラックのバッファを保持

    function initializePlayer() {
      audio.pause();
      audio.currentTime = 0;
      isPlaying = false;
      resetRotation();
      playPauseButton.classList.remove('is-pauseicon'); // 停止時に再生ボタンが表示されるようにする
      updateAlbumImage(playlist.albumImage); // 停止時にアルバムの画像を表示
      updateTrackInfo('---', '---'); // 停止時にはタイトルとアーティストに "---" を表示
      playlistElement.querySelectorAll('li').forEach((li) => {
        li.classList.remove('active');
      });
      currentTrackIndex = 0; // 停止後、次回再生時には1曲目から再生
      updateBodyClass('is-player-stop');
      updateControls();
    }

    function loadTrackBuffer(index) {
      const track = playlist.tracks[index];
      if (workers[index]) return; // 既にロード中であれば何もしない

      const worker = new Worker('/dist/assets/js/worker.js');
      workers[index] = worker;

      worker.postMessage({ url: track.file });

      worker.onmessage = function (e) {
        const { progress, buffer } = e.data;
        if (progress >= 0 && index === currentTrackIndex) {
          loadBar.style.width = `${progress}%`;
        }
        if (progress === 100 && buffer) {
          buffers[index] = buffer;
          workers[index].terminate();
          workers[index] = null;

          if (index === currentTrackIndex) {
            audio.src = URL.createObjectURL(new Blob([buffer], { type: 'audio/mp3' }));
            if (isPlaying) {
              audio.play().catch(() => {
                audio.pause();
              });
            }
          }
        }
      };
    }

    function loadTrack(index) {
      if (index >= playlist.tracks.length) return; // トラックが存在しない場合は終了

      currentTrackIndex = index;
      const track = playlist.tracks[currentTrackIndex];

      // イベントリスナーの重複を避けるために、新しいトラックをロードする前に既存のリスナーを削除
      audio.pause();
      audio.currentTime = 0;
      audio.removeEventListener('loadedmetadata', updateMetadata);
      audio.removeEventListener('ended', handleTrackEnd);

      updateAlbumImage(track.image);
      updateTrackInfo(track.title, track.artist); // 曲の情報を更新
      currentTimeElement.textContent = `0:00`;
      totalDurationElement.textContent = `0:00`;

      if (buffers[index]) {
        audio.src = URL.createObjectURL(new Blob([buffers[index]], { type: 'audio/mp3' }));
        if (isPlaying) {
          audio.play().catch(() => {
            audio.pause();
          });
        }
      } else {
        audio.src = track.file; // 未ロードの場合、直接URLを使用
        loadTrackBuffer(index); // 再生が開始されたタイミングでバッファのロードを開始
      }

      audio.addEventListener('loadedmetadata', updateMetadata);
      audio.addEventListener('ended', handleTrackEnd);

      playlistElement.querySelectorAll('li').forEach((li, i) => {
        li.classList.toggle('active', i === index);
      });

      resetRotation();
      startRotation();
      updateBodyClass('is-player-playing');
    }

    function updateAlbumImage(src) {
      const oldImage = musicPlayer.querySelector(".image-area img");
      if (oldImage) {
        imageArea.removeChild(oldImage);
      }

      const newImage = document.createElement("img");
      newImage.src = src;
      newImage.alt = "Album Art";
      newImage.style.transform = `rotate(${rotationAngle}deg)`;
      imageArea.appendChild(newImage);
    }

    function updateTrackInfo(title, artist) {
      trackTitleElement.textContent = title;
      trackArtistElement.textContent = artist;
    }

    function updateMetadata() {
      const duration = formatDuration(audio.duration);
      totalDurationElement.textContent = duration;
    }

    function handleTrackEnd() {
      if (currentTrackIndex < playlist.tracks.length - 1) {
        nextTrack();
      } else {
        initializePlayer();
      }
    }

    function playTrack() {
      if (!audio.src || document.body.classList.contains('is-player-stop')) {
        loadTrack(0); // 初期化後に再生ボタンを押したとき、1曲目をロードして再生する
      }
      audio.play().catch(() => {
        audio.pause();
      });
      isPlaying = true;
      playPauseButton.classList.add('is-pauseicon');
      startRotation();
      updateBodyClass('is-player-playing');
      updateControls();
    }

    function pauseTrack() {
      audio.pause();
      isPlaying = false;
      playPauseButton.classList.remove('is-pauseicon');
      stopRotation();
      updateBodyClass('is-player-pause');
      updateControls();
    }

    function prevTrack() {
      if (currentTrackIndex > 0) {
        loadTrack(--currentTrackIndex);
        playTrack();
      }
    }

    function nextTrack() {
      if (currentTrackIndex < playlist.tracks.length - 1) {
        loadTrack(++currentTrackIndex);
        playTrack();
      }
    }

    function updateControls() {
      prevButton.disabled = currentTrackIndex === 0;
      nextButton.disabled = currentTrackIndex === playlist.tracks.length - 1;
      if (document.body.classList.contains('is-player-playing') || document.body.classList.contains('is-player-pause')) {
        stopButton.disabled = false;
      } else if (document.body.classList.contains('is-player-stop')) {
        stopButton.disabled = true;
      }
    }

    function startRotation() {
      stopRotation();
      rotateImage();
    }

    function stopRotation() {
      cancelAnimationFrame(animationFrameId);
    }

    function resetRotation() {
      stopRotation();
      rotationAngle = 0;
      const currentImage = musicPlayer.querySelector(".image-area img");
      if (currentImage) {
        currentImage.style.transform = `rotate(0deg)`;
      }
    }

    function rotateImage() {
      rotationAngle += rotationSpeed;
      const currentImage = musicPlayer.querySelector(".image-area img");
      if (currentImage) {
        currentImage.style.transform = `rotate(${rotationAngle}deg)`;
      }
      animationFrameId = requestAnimationFrame(rotateImage);
    }

    function updateBodyClass(stateClass) {
      document.body.classList.remove('is-player-stop', 'is-player-playing', 'is-player-pause');
      document.body.classList.add(stateClass);
    }

    function formatDuration(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    }

    function updateSeekBar() {
      const progressPercentage = (audio.currentTime / audio.duration) * 100;
      progressBar.style.width = `${progressPercentage}%`;
      currentTimeElement.textContent = formatDuration(audio.currentTime);
    }

    function seekTrack(event) {
      const width = progressContainer.clientWidth;
      const clickX = event.offsetX;
      const duration = audio.duration;

      audio.currentTime = (clickX / width) * duration;
    }

    audio.addEventListener('timeupdate', updateSeekBar);
    progressContainer.addEventListener('click', seekTrack);

    playlist.tracks.forEach((track, index) => {
      const isAddIndex = playlistElement.dataset.addindex;
      const li = document.createElement("li");
      li.id = `track-${index}`;
      if (isAddIndex == "false" || isAddIndex == "0") {
        li.innerHTML = `
        <img src="${track.image}" alt="Track Art">
        <p><span class="font-style-italic">${track.title}</span> - ${track.artist} (<span class="duration">Loading...</span>)</p>
      `;
      } else {
        li.innerHTML = `
        <img src="${track.image}" alt="Track Art">
        <p>${index + 1}. <span class="font-style-italic">${track.title}</span> - ${track.artist} (<span class="duration">Loading...</span>)</p>
      `;
      }

      const trackAudio = new Audio(track.file);
      trackAudio.addEventListener('loadedmetadata', () => {
        const duration = formatDuration(trackAudio.duration);
        li.querySelector('.duration').textContent = duration;
      });

      li.addEventListener("click", () => {
        loadTrack(index); // 別の曲がクリックされた場合はその曲をロードして再生
        playTrack();
      });

      playlistElement.appendChild(li);
    });

    playPauseButton.addEventListener("click", () => {
      if (isPlaying) {
        pauseTrack();
      } else {
        playTrack();
      }
    });

    stopButton.addEventListener("click", () => {
      initializePlayer()
    });

    prevButton.addEventListener("click", prevTrack);
    nextButton.addEventListener("click", nextTrack);

    audio.addEventListener("ended", handleTrackEnd);

    initializePlayer();
  }
}