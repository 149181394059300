// Stylesheets
import './assets/scss/site.scss';

import { hover } from './assets/modules/hover';
import { scrollNav } from './assets/modules/scrollNav';
import { audioPlayer } from './assets/modules/audioPlayer';

const init = () => {
  hover();
  scrollNav();
  audioPlayer();
}

init();